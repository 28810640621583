import * as yup from "yup";


export const FIELD_CONDITION = {
    academicImage: {
      disabled: function (editMode) {
        return !editMode;
      },
    },
  };

export const validateAcademicToAdd = yup.object().shape({
    description: yup.string().required("error.field.required"),  
    universityId: yup.string().required("error.field.required"),
    universityUnitId: yup.string().required("error.field.required"),
    firstName: yup.string().required("error.field.required"),
    lastName: yup.string().required("error.field.required"),
    email: yup.string().email('error.field.invalid.email').required('error.field.required'),
    
});

export const validateAcademicToEdit = yup.object().shape({
  description: yup.string().required("error.field.required"),  
  universityId: yup.string().required("error.field.required"),
  universityUnitId: yup.string().required("error.field.required"),
  firstName: yup.string().required("error.field.required"),
  lastName: yup.string().required("error.field.required")  
});