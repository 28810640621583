import * as yup from "yup";
import {OPPORTUNITY_TYPE} from "./constants/back-office-constants";
import { parseYupDate } from "./date-utils";

export const FIELD_CONDITION = {
  coverImage: {
    disabled: function (type) {
      return !!type && type === OPPORTUNITY_TYPE.alert;
    },
  },
  hook: {
    disabled: function (type) {
      return !!type && type === OPPORTUNITY_TYPE.alert;
    },
  },
  deadline: {
    disabled: function (type) {
      return !!type && type === OPPORTUNITY_TYPE.alert;
    },
  },
  content: {
    disabled: function (type) {
      return !!type && type === OPPORTUNITY_TYPE.alert;
    },
  },
  link: {
    disabled: function (type) {
      return !!type && type === OPPORTUNITY_TYPE.alert;
    },
  },
  keyword: {
    disabled: function (type) {
      return !!type && type === OPPORTUNITY_TYPE.alert;
    },
  },
};

export const opportunitySchema = yup.object().shape({
  type: yup.string().required("error.field.required"),
  title: yup.string().required("error.field.required"),
  startDate: yup.date().required("error.field.required"),
  endDate: yup.date()
                .required("error.field.required")  
                .test(
                  "end-date-after-start-date",
                  "error.end.date.after.start.date",
                  function (endDate) {
                    const { startDate } = this.parent;
                    return endDate > startDate;
                  }),
  publicProfile: yup.array().min(1, "error.field.required"),
  publicCycle: yup.array().when(["publicProfile"], {
    is: (publicProfile) => {return publicProfile.filter((item) => item.code === "STUDENT").length > 0},
    then: yup.array().min(1, "error.field.required"),
  }),
  hasContent: yup.boolean().when(["type"],{
    is: (type) => { return type != OPPORTUNITY_TYPE.alert },
    then: yup.boolean().required().test("newsContent", "error.field.required", (hasContent) => { 
      return hasContent;
    }),
  })
});
