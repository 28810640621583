import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useIntl } from "gatsby-plugin-intl";


const AutoCompleteMonoValue = ({value, setValue, label, options, loading = false,  ...props}) => {
    const intl = useIntl();
    const [inputValue, setInputValue] = React.useState("");
    return (
        <Autocomplete
            {...props}
            loading={loading}
            value={value}
            freeSolo={true}
            isOptionEqualToValue={(options, value) => options.value === value.value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            options={options}
            renderOption={(props, option) => {
                return (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                );
              }}
            renderInput={(params) => (
                <TextField {...params}
                    label={intl.formatMessage({ id: label })}
                    placeholder={intl.formatMessage({ id: label })}
                    variant="standard"
                />
            )}
        />
    );
}

export default AutoCompleteMonoValue;