import React, { useEffect, useState } from "react";
import { Button, Avatar, Stack } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Controller } from "react-hook-form";
import { useIntl } from "gatsby-plugin-intl";
import themeMuiV5 from "components/common/ThemeMuiV5";

const styles = {
  stack: {
      spacing: 4,
      justifyContent: "center",
      alignItems: "center"
  },
  avatar: {
      width: 90,
      height: 90
  },
  uploadIcon: {
    marginRight: themeMuiV5.spacing(2)
  }
};

const AvatarUpload = ({ 
    label, 
    pictureFileName, 
    pictureSourceName, 
    useFormMethods,
    handleValidateAvatarChange, 
    avatarAlt='avatar upload', 
    disabled=false 
}) => {


  const intl = useIntl();
  const [isDisabled] = useState(disabled);

  const useDisplayPicture = () => {    
    const [src, setSrc] = useState(null);
    const [file, setFile] = useState(null);
    const displayPicture = (event) =>  {
      if(handleValidateAvatarChange(event)){
        const reader = new FileReader();
        reader.addEventListener("load", (e) => {
          setSrc(e.target.result);
        });
        reader.readAsDataURL(event.target.files[0]);
        setFile(event.target.files[0]);
      }      
    }
    return { src, file, displayPicture };
  }

  const { src, file, displayPicture } = useDisplayPicture();

  useEffect(() => {
    if(src){
      useFormMethods.setValue(pictureFileName, file);
      useFormMethods.setValue(pictureSourceName, src);
    }
  }, [src]);

  return (
    <Stack spacing={styles.stack.spacing} 
            justifyContent={styles.stack.justifyContent}
            alignItems={styles.stack.alignItems}>

      <Controller
        name={ pictureSourceName }
        control={useFormMethods.control}
        render={({ field }) => 
          <Avatar {...field} src={field.value} alt={avatarAlt} style={styles.avatar} 
        />}
      />

      <Stack>
        <label htmlFor="upload-picture" title="upload your picture">
          <input 
              id="upload-picture" 
              name="picture"               
              type="file" accept="image/*"
              onChange={(e) => {
                displayPicture(e);
              }}                    
              style={{ display: "none" }}
              disabled={isDisabled}      
          />
          <Button color="primary" variant="outlined" component="span" disabled={isDisabled}>
            <FontAwesomeIcon icon={faUpload} style={styles.uploadIcon} />{intl.formatMessage({id: label})}
          </Button>
        </label>
      </Stack>
      
    </Stack>
  );
}

export default AvatarUpload;
