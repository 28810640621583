import React from 'react';
import { Router } from '@gatsbyjs/reach-router';

import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';

import { PrivateRoutePath, PrivateRoutePathForAcademic } from 'router/PrivateRoute';
import { ROLE } from 'utils/constants/constants';

import themeMuiV5 from 'components/common/ThemeMuiV5';

import AddEditCoursePage from 'components/backoffice/catalog/course/AddEditCoursePage';
import CourseListPage from 'components/backoffice/catalog/courses/CourseListPage';

import AddEditNewsPage from 'components/backoffice/news/AddEditNewsPage';
import NewsListPage from 'components/backoffice/news/NewsListPage';

import AddEditAcademicPage from 'components/backoffice/academics/AddEditAcademicPage';
import AcademicListPage from 'components/backoffice/academics/AcademicListPage';

import EnrollmentList from 'components/backoffice/enrollments/EnrollmentList';
import EnrollmentLogList from 'components/backoffice/enrollments/EnrollmentLogList';

import DashboardBoPage from 'components/backoffice/DashboardBoPage';
import CertificatesPage from 'components/backoffice/certificates/CertificatesPage';
import LibraryPage from 'components/backoffice/library/LibraryPage';

import UserListPage from 'components/backoffice/users/UserListPage';
import EditUserPage from 'components/backoffice/users/EditUserPage';
import AddUserPage from 'components/backoffice/users/AddUserPage';

const BackOffice = () => {
  return (
    <ThemeProviderV5 theme={themeMuiV5}>
      <Router basepath="/:lang/back-office">

      <PrivateRoutePath
          path="/dashboard"
          roles={[ROLE.admin, ROLE.admin_university, ROLE.academics_manager, ROLE.courses_approver, ROLE.courses_manager, ROLE.news_manager]}
          component={DashboardBoPage}
        />

      <PrivateRoutePath
          path="/certificates"
          roles={[ROLE.admin, ROLE.admin_university]}
          component={CertificatesPage}
        />

      <PrivateRoutePath
          path="/library"
          roles={[ROLE.admin, ROLE.admin_university]}
          component={LibraryPage}
        /> 

      <PrivateRoutePath
          path="/users"
          roles={[ROLE.admin, ROLE.admin_university]}
          component={UserListPage}
        />

      <PrivateRoutePath
          path="/users/add"
          roles={[ROLE.admin, ROLE.admin_university]}
          component={AddUserPage}
        />

      <PrivateRoutePath
          path="/users/details/:userId"
          roles={[ROLE.admin, ROLE.admin_university]}
          component={EditUserPage}
        />

       <PrivateRoutePath
          path="/faculty"
          roles={[ROLE.admin, ROLE.admin_university, ROLE.academics_manager]}
          component={AcademicListPage}
        />

      <PrivateRoutePath
          path="/faculty/add"
          roles={[ROLE.admin, ROLE.admin_university, ROLE.academics_manager]}
          component={AddEditAcademicPage}
        />

      <PrivateRoutePathForAcademic
          path="/faculty/edit/:academicId"
          roles={[ROLE.admin, ROLE.admin_university, ROLE.academics_manager]}
          component={AddEditAcademicPage}
        />


        <PrivateRoutePath
          path="/courses"
          roles={[ROLE.user,ROLE.admin, ROLE.admin_university, ROLE.courses_approver, ROLE.courses_manager]}
          component={CourseListPage}
        />
        <PrivateRoutePath
          path="/courses/edit/:courseId"
          roles={[ROLE.user,ROLE.admin, ROLE.admin_university, ROLE.courses_approver, ROLE.courses_manager]}
          component={AddEditCoursePage}
        />
        <PrivateRoutePath
          path="/courses/new"
          roles={[ROLE.user,ROLE.admin, ROLE.admin_university, ROLE.courses_approver, ROLE.courses_manager]}
          component={AddEditCoursePage}
        />


        <PrivateRoutePath
          path="/opportunities/new"
          roles={[ROLE.admin, ROLE.admin_university, ROLE.news_manager]}
          component={AddEditNewsPage}
        />
         <PrivateRoutePath
          path="/opportunities"
          roles={[ROLE.admin, ROLE.admin_university, ROLE.news_manager]}
          component={NewsListPage}
        />
          <PrivateRoutePath
          path="/opportunities/edit/:opportunityId"
          roles={[ROLE.admin, ROLE.admin_university, ROLE.news_manager]}
          component={AddEditNewsPage}
        />


         <PrivateRoutePath
          path="/enrollments"
          roles={[ROLE.admin, ROLE.admin_university]}
          component={EnrollmentList}
        />
         <PrivateRoutePath
          path="/enrollments/:batchId"
          roles={[ROLE.admin, ROLE.admin_university]}
          component={EnrollmentLogList}
        />
      </Router>
  </ThemeProviderV5>
  );
};

export default BackOffice;
