
import React, { useState } from "react";
import LayoutBo from "components/backoffice/common/layout/LayoutBo";
import { useIntl } from "gatsby-plugin-intl";
import { Box, Card, CardContent, CardHeader, Tab, Tabs } from "@mui/material";
import themeMuiV5 from "components/common/ThemeMuiV5";
import EditUserProfileTab from "components/backoffice/users/tabs/EditUserProfileTab";
import EditUserRolesTab from "components/backoffice/users/tabs/EditUserRolesTab";
import EditUserCoursesTab from "components/backoffice/users/tabs/EditUserCoursesTab";
import EditUserDocumentsTab from "components/backoffice/users/tabs/EditUserDocumentsTab";

const tabTitles  = {
  profile: "backoffice.users.edit.cardHeader.profile",
  roles: "backoffice.users.edit.cardHeader.roles",
  courses: "backoffice.users.edit.cardHeader.courses",
  documents: "backoffice.users.edit.cardHeader.documents",
}

const CustomTabPanel = (props) => {
  const { children, id, value, index, ariaLabel, ...other } = props;
  return (
    <div id={id} hidden={value !== index} aria-labelledby={ariaLabel} role="tabpanel" 
          style={{ height: '100%' }} {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}

const EditUserPage = ({userId}) => {
    const intl = useIntl();   
    const [activeTab, setActiveTab] = useState("profile");
    const [title, setTitle] = useState(tabTitles["profile"]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        setTitle(tabTitles[newValue]);
    };
    
    return (
    <>
      <LayoutBo>

        <Card sx={{
            backgroundColor: themeMuiV5.palette.background.paper,
            height: "100%",
            paddingTop: themeMuiV5.spacing(1),
            color: themeMuiV5.palette.primary.main
        }}>

          <CardHeader 
              sx={{
                  backgroundColor: themeMuiV5.palette.primary.main,
                  textAlign: "center",
                  paddingTop: themeMuiV5.spacing(1),
                  color: themeMuiV5.palette.primary.contrastText
              }}
              title={ intl.formatMessage({id: title}) }
          />

          <CardContent sx={{
              height: "100%",
              backgroundColor: themeMuiV5.palette.background.paper
            }}
          >
              <Box sx={{ height: '100%', bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs 
                        value={activeTab} 
                        onChange={handleTabChange} 
                        aria-label="user edit" 
                        variant="scrollable"
                        sx={{ height: '100%' }}
                  >
                      <Tab label={intl.formatMessage({id: "backoffice.users.edit.tab.title.profile"})} value="profile" sx={{ height: '100%' }} />
                      <Tab label={intl.formatMessage({id: "backoffice.users.edit.tab.title.roles"})}  value="roles" />
                      <Tab label={intl.formatMessage({id: "backoffice.users.edit.tab.title.courses"})}  value="courses" />
                      <Tab label={intl.formatMessage({id: "backoffice.users.edit.tab.title.documents"})}  value="documents" />
                  </Tabs>
              </Box>

              <CustomTabPanel id="profile" index="profile" value={activeTab} ariaLabel="profile">
                  <EditUserProfileTab userId={ userId } />
              </CustomTabPanel>
              <CustomTabPanel id="roles" index="roles" value={activeTab} ariaLabel="roles">
                  <EditUserRolesTab userId={ userId }/>
              </CustomTabPanel>
              <CustomTabPanel id="courses" index="courses" value={activeTab} ariaLabel="courses">
                  <EditUserCoursesTab userId={ userId }/>
              </CustomTabPanel>
              <CustomTabPanel id="documents" index="documents" value={activeTab} ariaLabel="documents">
                  <EditUserDocumentsTab userId={ userId }/>
              </CustomTabPanel>
          </CardContent>
          
        </Card>                      
      </LayoutBo>    
    </>
    );
}

export default EditUserPage;