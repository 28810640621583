import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';

import { useIntl } from "gatsby-plugin-intl";
import { IconButton } from "@mui/material";


function PdfDialog( { 
          open, onClose: handleClose , base64PdfData,
          dialogTitle = "pdfDialog.dialogTitle",
    } 
) {  
    const intl = useIntl();
    return (
      <div>
        <Dialog
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                  whiteSpace:  'pre-wrap',
              },
              sx: {
                minHeight: '100vh',
                maxHeight: '100vh',
                minWidth: '100vh',
                maxWidth: '100vh'
              }
           }}
        >
          <DialogTitle id="alert-dialog-title" 
            sx={{ 
              alignContent: "center", 
              height: "40px", 
              paddingTop: "4px"
            }} >
            {intl.formatMessage({id: dialogTitle})}
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{ float: "right", paddingTop: "4px" }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          
          <DialogContent sx={{
            display: "flex", flexGrow: 1, flexDirection: "column", padding: 0
          }}
          >
              <object 
                  data={`data:application/pdf;base64,${base64PdfData}`} 
                  type="application/pdf" 
                  width="100%" 
                  height="100%"
                  style={{ flexGrow: 1 }}
              >
                  <p>{intl.formatMessage({id: "pdfDialog.message.no.pdf"})}</p>
              </object> 
          </DialogContent>

          <DialogActions sx={{ 
            border: "1px solid black", justifyContent: "center"
          }}>
            <Button variant="contained" onClick={handleClose} autoFocus>
              {intl.formatMessage({id: "pdfDialog.dialogActionClose"})}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  export default PdfDialog;